import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';

/**
 * Returns the value as a number. Similar to asNumber but doesn't default to zero.
 * @param value The value to be converted to a number.
 * @param defaultValue the default value if not a number.
 */
export const asNumberOrDefault = (
  value: number | string | undefined,
  defaultValue: number | undefined
): number => {
  if (isNil(value)) {
    return defaultValue;
  }
  if (isNumber(value)) {
    return isNaN(value as number) ? defaultValue : (value as number);
  }
  try {
    const isNegative = (value as string).startsWith('-');
    const cleaned = (value as string).replace(/[^0-9|-]*/gi, '');
    if (!cleaned) {
      return defaultValue;
    }
    const parsed = parseInt(isNegative ? `-${cleaned}` : cleaned, 10);
    return isNaN(parsed) ? defaultValue : parsed;
  } catch {}
  return defaultValue;
};

/**
 * Returns the value as a number or undefined if not a valid number.
 *
 * @param value The value to be converted to a number.
 */
export const asNumberOrUndefined = (
  value: number | string | undefined
): number => {
  return asNumberOrDefault(value, undefined);
};

/**
 * Returns the value as a number.
 *
 * @param value The value to be converted to a number.
 * @param defaultValue the default value if not a number, default is 0.
 */
export const asNumber = (
  value: number | string | undefined,
  defaultValue: number | undefined = 0
): number => {
  return asNumberOrDefault(value, defaultValue);
};
