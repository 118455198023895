import { Guid } from '@komo-tech/core/models/Guid';
import { utcDateOrUndefined } from '@komo-tech/core/utils/date';
import isAfter from 'date-fns/isAfter';
import { persist, StateStorage } from 'zustand/middleware';
import { createWithEqualityFn } from 'zustand/traditional';

type ITriviaQuestionsState = { qId: string; gId: string; start?: Date };
interface State {
  siteId: string;
  userId: string;
  pageId: string;
  pageSlug: string;
  checklistStartedIds: string[];
  numberGuessTouchedIds: string[];
  triviaQuestionsState: ITriviaQuestionsState[];
  triviaCurrentPage: string;
  embeddedHostUrl: string;
}

interface Actions {
  init: (siteId: Guid, userId: Guid, pageId?: Guid, pageSlug?: string) => void;
  setUserId: (userId: Guid) => void;
  setTriviaQuestionState: (
    gameplayId: Guid,
    questionId: Guid,
    start?: Date
  ) => void;
  getTriviaQuestionStart: (
    gameplayId: Guid,
    questionId: Guid
  ) => Date | undefined;
  setTriviaQuestionStart: (gameplayId: Guid, questionId: Guid) => void;
  setTriviaCurrentPage: (page: string) => void;
  setEmbeddedHostUrl: (url: string) => void;
  setChecklistStarted: (cardId: Guid) => void;
  hasChecklistStarted: (cardId: Guid) => boolean;
  setNumberGuessTouched: (cardId: Guid) => void;
  hasTouchedNumberGuess(cardId: Guid): boolean;
}

interface ISiteCacheStore extends State, Actions {}

const resolveClearedValues = (): Omit<
  State,
  'userId' | 'siteId' | 'pageId' | 'pageSlug'
> => ({
  checklistStartedIds: [],
  numberGuessTouchedIds: [],
  triviaQuestionsState: [],
  triviaCurrentPage: null,
  embeddedHostUrl: null
});

export const SiteCache = createWithEqualityFn<
  ISiteCacheStore,
  [['zustand/persist', StateStorage]]
>(
  persist(
    (set, get) => ({
      siteId: null,
      userId: null,
      pageId: null,
      pageSlug: null,
      checklistStartedIds: [],
      numberGuessTouchedIds: [],
      triviaQuestionsState: [],
      triviaCurrentPage: null,
      embeddedHostUrl: null,
      init: (siteId, userId, pageId, pageSlug) => {
        const currentState = get();
        const sameSite = siteId.equals(currentState.siteId);
        const sameUser = userId.equals(currentState.userId);
        const samePage =
          pageId?.equals(currentState.pageId) &&
          pageSlug === currentState.pageSlug;

        if (!sameSite || !sameUser || !samePage) {
          set({
            siteId: siteId.toString(),
            userId: userId.toString(),
            pageId: pageId?.toString(),
            pageSlug,
            ...resolveClearedValues()
          });
        }
      },
      setTriviaQuestionState: (gId, qId, start) => {
        const { triviaQuestionsState } = get();
        const newState = triviaQuestionsState.filter(
          (x) => gId.equals(x.gId) && qId.equals(x.qId)
        );
        const now = new Date();
        newState.push({
          gId: gId.toString(),
          qId: qId.toString(),
          start: isAfter(start, now) ? now : start
        });
        set({ triviaQuestionsState: newState });
      },
      setTriviaQuestionStart: (gameplayId, questionId) => {
        const newState = get().triviaQuestionsState as ITriviaQuestionsState[];
        const index = newState.findIndex(
          (x) => questionId.equals(x.qId) && gameplayId.equals(x.gId)
        );
        if (index < 0) {
          newState.push({
            qId: questionId.toString(),
            gId: gameplayId.toString(),
            start: new Date()
          });
        } else {
          //If we already have a start time then ignore the setter
          if (newState[index].start) return;
          newState[index].start = new Date();
        }

        set({ triviaQuestionsState: newState });
      },
      getTriviaQuestionStart: (gameplayId, questionId) => {
        const { triviaQuestionsState } = get();
        const qState = triviaQuestionsState.find(
          (x) => gameplayId.equals(x.gId) && questionId.equals(x.qId)
        );
        return utcDateOrUndefined(qState?.start);
      },
      setUserId: (userId: Guid) => {
        const state = get();
        if (userId?.toString() !== state.userId?.toString()) {
          set({
            userId: userId.toString(),
            siteId: state.siteId,
            ...resolveClearedValues()
          });
        }
      },
      setChecklistStarted: (cardId) => {
        const { checklistStartedIds } = get();
        set({
          checklistStartedIds: [...checklistStartedIds, cardId.toString()]
        });
      },
      hasChecklistStarted: (cardId) => {
        return get().checklistStartedIds.includes(cardId.toString());
      },
      setNumberGuessTouched: (cardId) => {
        const { numberGuessTouchedIds: numberGuessInteractedIds } = get();
        set({
          numberGuessTouchedIds: [
            ...numberGuessInteractedIds,
            cardId.toString()
          ]
        });
      },
      hasTouchedNumberGuess: (cardId) => {
        return get().numberGuessTouchedIds.some((id) => cardId.equals(id));
      },
      setTriviaCurrentPage: (page: string) => set({ triviaCurrentPage: page }),
      setEmbeddedHostUrl: (url: string) => set({ embeddedHostUrl: url })
    }),
    {
      name: 'komo-site-cache'
    }
  ),
  Object.is
);

export const useSiteCache = SiteCache;
