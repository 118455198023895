import isNil from 'lodash/isNil';

/**
 * Returns if the value is boolean.
 * @param value True if a boolean, else false.
 */
export const isBoolean = (value: unknown) => typeof value === 'boolean';

/**
 * Converts the specified value to boolean.
 * @param value The value to parse.
 * @param defaultValue The default value if not a valid boolean value (default false).
 * @returns The boolean value.
 */
export const asBoolean = (
  value?: string | boolean | undefined | null,
  defaultValue: boolean = false
) => {
  if (isBoolean(value)) {
    return value as boolean;
  }
  if (isNil(value)) {
    return defaultValue;
  }
  switch (value.toString().toLowerCase()) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return defaultValue;
  }
};

/**
 * Converts the specified boolean to a string
 * @param value
 * @returns The string value
 */
export const booleanAsString = (value?: boolean) => {
  return Boolean(value).toString();
};
