import { Page } from '@komo-tech/core/models/Page';

import { ApiConfigBuilder, ApiRequestConfig } from './Api.types';
import { ApiHelper } from './ApiHelper';

export abstract class ApiBase {
  private readonly _basePathFn: () => string;
  private readonly _configBuilder: ApiConfigBuilder;

  protected constructor(
    basePathFn: () => string,
    configBuilder?: ApiConfigBuilder
  ) {
    this._basePathFn = basePathFn;
    this._configBuilder = configBuilder;
  }

  public getAbsolutePath(path: string): string {
    const basePath = this._basePathFn();
    const cleanPath =
      path.startsWith('/') && path.length > 1 ? path.substr(1) : path;
    return basePath ? `${basePath}/${cleanPath}` : cleanPath;
  }

  public getAsync<T>(path: string, config?: ApiRequestConfig): Promise<T> {
    return ApiHelper.getAsync<T>(
      this.getAbsolutePath(path),
      this._buildConfig(config)
    );
  }

  public async getPageAsync<T>(
    path: string,
    itemMap: (x: any) => T,
    config?: ApiRequestConfig
  ): Promise<Page<T>> {
    const { items, ...rest } = await ApiHelper.getAsync<Page<T>>(
      this.getAbsolutePath(path),
      this._buildConfig(config)
    );
    return new Page<T>({ ...rest, items: (items || []).map(itemMap) });
  }

  public async postPageAsync<T>(
    path: string,
    body: any,
    itemMap: (x: any) => T,
    config?: ApiRequestConfig
  ): Promise<Page<T>> {
    const { items, ...rest } = await ApiHelper.postAsync<Page<T>>(
      this.getAbsolutePath(path),
      body,
      this._buildConfig(config)
    );
    return new Page<T>({ ...rest, items: (items || []).map(itemMap) });
  }

  public postAsync<T>(
    path: string,
    body?: any,
    config?: ApiRequestConfig & { sendRawBody?: boolean }
  ): Promise<T> {
    return ApiHelper.postAsync<T>(
      this.getAbsolutePath(path),
      body,
      this._buildConfig(config)
    );
  }

  public postFormData<T>(
    path: string,
    body?: any,
    config?: ApiRequestConfig
  ): Promise<T> {
    return ApiHelper.postFormData<T>(
      this.getAbsolutePath(path),
      body,
      this._buildConfig(config)
    );
  }

  public canSendBeacon(): boolean {
    return ApiHelper.canSendBeacon();
  }

  public sendBeacon(path: string, body?: any): boolean {
    return ApiHelper.sendBeacon(this.getAbsolutePath(path), body);
  }

  public putAsync<T>(
    path: string,
    body?: any,
    config: ApiRequestConfig = {}
  ): Promise<T> {
    return ApiHelper.putAsync<T>(
      this.getAbsolutePath(path),
      body,
      this._buildConfig(config)
    );
  }

  public deleteAsync<T>(
    path: string,
    config: ApiRequestConfig = {}
  ): Promise<T> {
    return ApiHelper.deleteAsync<T>(
      this.getAbsolutePath(path),
      this._buildConfig(config)
    );
  }

  private _buildConfig(config?: ApiRequestConfig): ApiRequestConfig {
    return this._configBuilder ? this._configBuilder(config) : config;
  }
}
