import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';

import { roundTo2Decimals } from '../_internal/roundTo';
import { decodeBlurHash } from '../utils/blurHash';
import { getClampedSize } from '../utils/size';
import { UrlBuilder } from '../utils/UrlBuilder';
import { HeightWidth } from './HeightWidth';

/**
 * Our internal model for representing an image.
 *
 * The width and height should be natural size of the image, not the read size.
 */

export class ImageDataModel {
  private _fallbackAspectRatio?: HeightWidth;
  url: string;
  alt?: string;
  height?: number;
  width?: number;
  cropCoordinates?: ImageBoxCropData;
  blurHash?: string;

  get hasFallbackAspectRatio() {
    return !!this.fallbackAspectRatio;
  }

  get fallbackAspectRatio() {
    return this._fallbackAspectRatio;
  }

  get hasCropCoordinates(): boolean {
    return !!this.cropCoordinates;
  }

  get size(): HeightWidth {
    if (!this.height || !this.width) return undefined;
    return { width: this.width, height: this.height };
  }

  constructor(props?: Partial<ImageDataModel>) {
    props = props || {};
    Object.assign(this, props);
    this.cropCoordinates = new ImageBoxCropData(this.cropCoordinates);
  }

  toString(): string {
    return JSON.stringify(this);
  }

  setFallbackAspectRatio(ratio: HeightWidth) {
    this._fallbackAspectRatio = ratio;
  }

  setCropCoordinates(data: ImageBoxCropData) {
    this.cropCoordinates = data;
  }

  getCropCoordinatesQueryValue() {
    return this.cropCoordinates.getCoordinatesQueryValue();
  }

  getClampedSize(maxSize: HeightWidth): HeightWidth {
    const size = this.size;
    if (size) {
      return getClampedSize({
        size,
        maxWidth: maxSize.width,
        maxHeight: maxSize.height,
        roundingDecimals: 0
      });
    } else {
      return { ...maxSize };
    }
  }

  getRenderData(): ImageRenderData {
    const height = this?.height;
    const width = this?.width;

    return {
      imageUrl: this?.url,
      imageAlt: this.alt,
      blurDataUrl: !this?.blurHash
        ? undefined
        : decodeBlurHash({ hash: this?.blurHash, width, height }),
      height,
      width,
      fallbackAspectRatio: this.fallbackAspectRatio
    };
  }

  /**
   * Calculate what scale the image needs to be the height of a container
   * @param image
   * @param bounds
   * @param fallback
   * @param min
   * @param max
   */
  calculateScaleToFillHeight(
    bounds: HeightWidth,
    fallback: number,
    min: number,
    max: number
  ): number {
    if (!bounds.width || !bounds.height || !this.width) {
      return fallback;
    }

    const ratio = this.height / this.width;
    const scale = bounds.height / (ratio * bounds.width);

    return Math.min(max, Math.max(min, scale));
  }

  setQuality(quality: number = 82) {
    this.url = new UrlBuilder({ disableTrailingSlash: true })
      .withUrlAndQuery(this.url)
      .withQuery('quality', quality.toString())
      .build();
  }

  setFormat(format: string) {
    this.url = new UrlBuilder({ disableTrailingSlash: true })
      .withUrlAndQuery(this.url)
      .withQuery('format', format)
      .build();
  }

  tryApplyCropCoordinates(data: ImageBoxCropData) {
    if (data?.isValid?.()) {
      this.url = new UrlBuilder({ disableTrailingSlash: true })
        .withUrlAndQuery(this.url)
        .withQuery('cc', data.getCoordinatesQueryValue())
        .build();
    }
  }

  static fromJsonOrUrl(
    value: string | ImageDataModel,
    fallbackAspectRatio?: HeightWidth
  ): ImageDataModel | null {
    if (isNil(value)) {
      return null;
    }
    if (isObject(value)) {
      return new ImageDataModel(value);
    }

    const stringValue = (value || '') as string;
    if (!stringValue.trim()) {
      return null;
    }

    try {
      const model = JSON.parse(stringValue);
      return new ImageDataModel(model);
    } catch {
      const urlModel = new ImageDataModel({ url: stringValue });
      urlModel.setFallbackAspectRatio(fallbackAspectRatio);
      return urlModel;
    }
  }

  static fromUrlAndSize(url: string, size: HeightWidth) {
    return new ImageDataModel({
      url,
      height: size.height,
      width: size.width
    });
  }
}

export type ImagesPromiseFunc = () => Promise<ImageDataModel[]>;

export interface ImageRenderData {
  imageUrl?: string;
  imageAlt?: string;
  blurDataUrl?: string;
  height?: number;
  width?: number;
  fallbackAspectRatio?: HeightWidth;
}

export class ImageBoxCropData {
  cropXFraction: number;
  cropYFraction: number;
  cropWidthFraction: number;
  cropHeightFraction: number;

  constructor(props: Partial<ImageBoxCropData>) {
    props = props || {};
    Object.assign(this, props);
  }

  isValid(): boolean {
    return (
      !isNil(this.cropXFraction) &&
      !isNil(this.cropYFraction) &&
      !isNil(this.cropWidthFraction) &&
      !isNil(this.cropHeightFraction)
    );
  }

  getCoordinatesQueryValue() {
    if (!this.isValid()) {
      return '0,0,1,1';
    }

    return `${roundTo2Decimals(this.cropXFraction)},${roundTo2Decimals(
      this.cropYFraction
    )},${roundTo2Decimals(this.cropWidthFraction)},${roundTo2Decimals(
      this.cropHeightFraction
    )}`;
  }
}
