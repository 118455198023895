import { isServer } from '@komo-tech/core/utils/browser';
import { UrlBuilder } from '@komo-tech/core/utils/UrlBuilder';

export const DefaultFavIconPath = '/assets/images/favicon.ico';
export const KomoUtmSourcePrefix = 'Komo ';

const KomoServerApiBaseUrl = (() => {
  const url = process?.env?.API_URL;
  if (url) {
    return url.endsWith('/') ? url.substr(0, url.length - 1) : url;
  }
  return 'http://localhost:5002';
})();

export const KomoApiBaseUrl = isServer()
  ? KomoServerApiBaseUrl
  : `${window.location.protocol}//${window.location.host}`;

type UtmOptions =
  | {
      type: 'None';
    }
  | {
      type: 'Portal' | 'Site';
      medium: string;
    };

interface MarketingUrlOptions {
  utm: UtmOptions;
}

export const KomoUtmParamResolver = (
  url: string,
  { utm }: MarketingUrlOptions
) => {
  let builder = new UrlBuilder({
    disableTrailingSlash: true
  }).withUrlAndQuery(url);

  switch (utm.type) {
    case 'Portal':
    case 'Site':
      builder = builder
        .withQuery('utm_source', `${KomoUtmSourcePrefix}${utm.type}`)
        .withQuery('utm_medium', utm.medium);
      break;
  }

  return builder.build();
};

export const CardResources = {
  terms: 'https://knowledge.komo.tech/terms-conditions',
  cardTesting: 'https://knowledge.komo.tech/card-testing'
};

export const MarketingUrls = {
  terms: 'https://www.komo.tech/hubfs/Komo%20Terms%20of%20Service.pdf',
  referrals: {
    info: (options: MarketingUrlOptions) =>
      KomoUtmParamResolver(
        'https://offer.komo.tech/en/customer-referral',
        options
      ),
    terms:
      'https://2534775.fs1.hubspotusercontent-na1.net/hubfs/2534775/TermsAndConditions/2024/v2_Komo_Customer_Referral_Program_T%26Cs.pdf'
  },
  help: {
    whiteLabellingDomain: (options: MarketingUrlOptions) =>
      KomoUtmParamResolver(
        'https://knowledge.komo.tech/whitelabelling',
        options
      ),
    dataLists: (options: MarketingUrlOptions) =>
      KomoUtmParamResolver('https://knowledge.komo.tech/data-lists', options),
    referenceTables: (options: MarketingUrlOptions) =>
      KomoUtmParamResolver(
        'https://knowledge.komo.tech/reference-tables',
        options
      ),
    companyMembers: (options: MarketingUrlOptions) =>
      KomoUtmParamResolver(
        'https://knowledge.komo.tech/add-new-members-to-your-company',
        options
      ),
    launchingCampaign: (options: MarketingUrlOptions) =>
      KomoUtmParamResolver(
        'https://knowledge.komo.tech/go-live-checklist',
        options
      ),
    badges: (options: MarketingUrlOptions) =>
      KomoUtmParamResolver(
        'https://knowledge.komo.tech/badges-feature',
        options
      )
  },
  site: (options: MarketingUrlOptions) =>
    KomoUtmParamResolver('https://www.komo.tech', options),
  privacyPolicy: (options: MarketingUrlOptions) =>
    KomoUtmParamResolver('https://www.komo.tech/privacy-policy', options),
  contactUs: (options: MarketingUrlOptions) =>
    KomoUtmParamResolver('https://www.komo.tech/contact-us', options),
  digitalRewards: (options: MarketingUrlOptions) =>
    KomoUtmParamResolver('https://www.komo.tech/digital-rewards', options),
  buildYourFirstHub: (options: MarketingUrlOptions) =>
    KomoUtmParamResolver(
      'https://knowledge.komo.tech/building-your-first-hub',
      options
    )
};

// This list is also on the backend, ensure they are kept in sync if modified
// Pages/Models/SlugReservedKeywords.cs
// Reserved keywords should be lowercase
export const ReservedSlugKeywords = [
  // General Reserved Words
  'home',
  'portal',
  'admin',
  'card',
  'root',
  'system',
  'test',
  'example',
  'default',
  'null',
  'void',
  'api',
  'webhook',
  'backend',
  'frontend',
  // HTTP Methods
  'get',
  'post',
  'put',
  'delete',
  'patch',
  'head',
  'options',
  // Common URL Parameters
  'id',
  'page',
  'query',
  'search',
  'filter',
  'sort',
  'share',
  'link',
  // SEO and Analytics
  'sitemap',
  'robots',
  'google',
  'bing',
  'yahoo',
  'facebook',
  'twitter',
  // User Authentication and Management
  'auth',
  'login',
  'logout',
  'register',
  'signup',
  'signin',
  'profile',
  'user',
  'account',
  // Communication Subscriptions
  'subscriptions',
  // CMS and E-commerce Terms
  'coupon',
  'cart',
  'checkout',
  'order',
  'product',
  'category',
  'blog',
  'post',
  'article',
  // File and Media Management
  'upload',
  'download',
  'image',
  'video',
  'media',
  'file',
  // Reserved Words in Programming Languages (to avoid conflicts)
  'for',
  'while',
  'if',
  'else',
  'class',
  'function',
  'return',
  'true',
  'false',
  'var',
  'const',
  'let'
];
