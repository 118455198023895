import { IApiError } from '@komo-tech/core/models/IApiError';

export interface ApiOrderRequest {
  orderBy?: string;
  orderByDirection?: string;
}

export interface ApiPagedRequest {
  skip: number;
  take: number;
}

export type ApiOrderedPagedRequest = ApiPagedRequest & ApiOrderRequest;

export class ApiError extends Error implements IApiError {
  private _message: string;
  private _response: Response;
  private _model: ApiErrorModel;

  constructor(response: Response, message: string = undefined) {
    message = message || response.toString();
    super(`[${response.status}] ${message}`);
    this._response = response;
    this._message = message;
    this._model = ApiErrorModel.fromJsonOrDefault(message);
  }

  get response(): Response {
    return this._response;
  }

  get message(): string {
    return this._message;
  }

  isUnhandledException() {
    return this._model?.unhandledException;
  }

  public toString(): string {
    return `[${this._response.status}] ${this._message}`;
  }

  get errorMessage(): string {
    return this._model.getErrorMessage();
  }

  public static create = async (response: Response) => {
    const text = await response.text();
    return new ApiError(response, text);
  };

  public static fromMessage = (message: string) => {
    return new ApiError({ status: 400 } as any, message);
  };

  static tryGetMessage(
    error?: unknown,
    fallback: string = 'An error occurred'
  ) {
    if (isString(error)) {
      return error;
    }
    return (error as IApiError)?.errorMessage || fallback;
  }
}

export class ApiErrorModel {
  message: string;
  validationErrors: string[];
  unhandledException?: boolean = false;
  exception?: string;

  get hasValidationErrors() {
    return !!this.validationErrors?.length;
  }

  constructor(props?: Partial<ApiErrorModel>) {
    props = props || {};
    Object.assign(this, props);

    if (!this.validationErrors) this.validationErrors = [];
  }

  static fromJsonOrDefault(json: string) {
    try {
      return new ApiErrorModel(JSON.parse(json));
    } catch (error) {
      return new ApiErrorModel({
        unhandledException: true,
        message: 'An error occurred'
      });
    }
  }

  getErrorMessage() {
    if (this.hasValidationErrors) {
      return `Validation failed: \r\n ${this.validationErrors.join('\r\n')}`;
    }

    return this.message || 'An error occurred. Please try again';
  }
}

//Taken from lodash. Next blows up if lodash is in here.
//I think its pretty cool
function isString(value: unknown): value is string {
  const type = typeof value;
  return (
    type === 'string' ||
    (type === 'object' &&
      value != null &&
      !Array.isArray(value) &&
      getTag(value) === '[object String]')
  );
}

const toString = Object.prototype.toString;

function getTag(value: unknown) {
  if (value == null) {
    return value === undefined ? '[object Undefined]' : '[object Null]';
  }
  return toString.call(value);
}

export type ApiRequestConfig = RequestInit & {
  responseHandler?: (response: Response) => any | undefined;
  errorHandlerAsync?: (response: Response) => Promise<any>;
};

export type ApiResponseHandler = (response: Response) => any | undefined;

export type ApiConfigBuilder = (config: ApiRequestConfig) => ApiRequestConfig;
