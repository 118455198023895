import isNil from 'lodash/isNil';

export const roundTo2Decimals = (value: number) => roundTo(value, 2);

export const roundTo = (value: number, decimalPlaces: number) => {
  if (isNil(value) || value === 0 || decimalPlaces < 0) {
    return value;
  }

  if (decimalPlaces === 0) {
    return Math.round(value);
  }

  return parseFloat(value.toFixed(decimalPlaces));
};
