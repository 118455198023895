import { QueryParams } from '../models/QueryParams';
import { isServer } from './browser';
import { getParamsFromUrl } from './url';

export interface UrlBuilderOptions {
  disableTrailingSlash?: boolean;
}

export class UrlBuilder {
  private _baseUrl: string;
  private readonly _queryParams: QueryParams;
  private _options: UrlBuilderOptions;

  constructor(options?: UrlBuilderOptions) {
    this._options = options || {};
    if (!isServer()) {
      this._baseUrl = window.location.origin;
    }
    this._queryParams = new QueryParams();
  }

  build() {
    let basePath = this._baseUrl;
    if (!this._options.disableTrailingSlash && !basePath.endsWith('/')) {
      basePath = `${basePath}/`;
    }
    return !this._queryParams.length
      ? basePath
      : `${basePath}${this._queryParams}`;
  }

  /**
   * Client side only - include the query params from the current window location
   */
  withCurrentQueryParamsFromUrl() {
    const [, records] = getParamsFromUrl(window.location.href);
    if (records) {
      Object.keys(records).forEach((key) => {
        this._queryParams.set(key, records[key]);
      }, this);
    }
    return this;
  }

  withCurrentQueryParamsFrom(
    query: URLSearchParams | NodeJS.Dict<string | string[]>
  ) {
    if (!query) {
      return this;
    }

    Object.keys(query).forEach((x) => {
      const val = query[x];
      if (typeof val === 'string') {
        this.withQuery(x, encodeURIComponent(val));
      } else {
        if (Array.isArray(val)) {
          val.forEach(
            (subValue) => this.withQuery(x, encodeURIComponent(subValue)),
            this
          );
        }
      }
    }, this);

    return this;
  }

  /**
   * Client side only - use the window location origin as the base url
   */
  withBaseUrlFromOrigin() {
    this._baseUrl = window.location.origin;
    return this;
  }

  withBaseUrlFromNext(router: {
    pathname: string;
    query: URLSearchParams | NodeJS.Dict<string | string[]>;
  }) {
    const { pathname, query } = router;
    let url = pathname;
    Object.keys(query).forEach((key) => {
      url = url.replace(`[${key}]`, query[key].toString());
    });
    this._baseUrl = url;
    return this;
  }

  withBaseUrl(url: string) {
    this._baseUrl = url;
    return this;
  }

  withUrlAndQuery(fullUrl: string) {
    if (!fullUrl) return this;
    try {
      const urlQuerySplit = fullUrl.split('?');
      if (urlQuerySplit.length === 1) return this.withBaseUrl(fullUrl);

      this.withBaseUrl(urlQuerySplit[0]);

      const fullQuery = urlQuerySplit[1];
      const rawQueries = fullQuery.split('&');
      rawQueries.forEach((q) => {
        const keyValue = q.split('=');
        this.withQuery(keyValue[0], keyValue[1]);
      });

      return this;
    } catch (e) {
      console.error(e);
      return this;
    }
  }

  withQuery(key: string, value: string) {
    this._queryParams.set(key, value);
    return this;
  }

  withoutQueryKeys(keys: string[]) {
    keys.forEach((key) => {
      if (this._queryParams.has(key)) {
        this._queryParams.delete(key);
      }
    }, this);

    return this;
  }

  withoutQueryKey(key: string) {
    return this.withoutQueryKeys([key]);
  }
}
