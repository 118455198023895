import { ApiError, ApiRequestConfig } from './Api.types';

const isOk = (res: Response) => res.status >= 200 && res.status < 400;

const getTimeZoneHeaders = () => {
  try {
    return {
      'x-timezone-id': Intl.DateTimeFormat().resolvedOptions().timeZone ?? ''
    };
  } catch {
    return {};
  }
};

const getTimeZoneOffsetHeaders = () => {
  try {
    let offset = new Date().getTimezoneOffset();
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getTimezoneOffset
    // We need to invert the offset as it's the opposite of what you think
    offset = offset === 0 ? 0 : offset * -1;
    return { 'x-timezone-offset': String(offset) };
  } catch {
    return {};
  }
};

const combineHeaders = (config: HeadersInit, isJson: boolean = true) => {
  const contentTypeHeaders = isJson
    ? {
        'Content-Type': 'application/json'
      }
    : undefined;
  return {
    ...contentTypeHeaders,
    ...getTimeZoneHeaders(),
    ...getTimeZoneOffsetHeaders(),
    ...config
  };
};

const isJson = (headers: Headers) =>
  headers.has('content-type') &&
  headers.get('content-type').indexOf('application/json') > -1;

const handleErrorAsync = async (
  response: Response,
  config?: ApiRequestConfig
) => {
  if (config?.errorHandlerAsync) {
    await config.errorHandlerAsync(response);
  } else {
    throw await ApiError.create(response);
  }
};

async function handleResponseAsync<T = unknown>(
  response: Response,
  config?: ApiRequestConfig
) {
  if (!isOk(response)) {
    await handleErrorAsync(response, config);
    return null;
  }

  if (config?.responseHandler) {
    const result = config.responseHandler(response);
    if (result !== undefined) {
      return result as T;
    }
  }

  return (
    isJson(response.headers) ? await response.json() : await response.text()
  ) as T;
}

export const ApiHelper = {
  isResponseOk: isOk,

  async getAsync<T = unknown>(
    url: string,
    config?: ApiRequestConfig
  ): Promise<T> {
    config = config || {};
    const response = await fetch(url, {
      method: 'GET',
      ...config,
      headers: { ...combineHeaders(config.headers) }
    });

    return await handleResponseAsync(response, config);
  },

  async postAsync<T = unknown>(
    url: string,
    body: any,
    config?: ApiRequestConfig & { sendRawBody?: boolean }
  ): Promise<T> {
    config = config || {};
    const { sendRawBody, ...configRest } = config;
    const response = await fetch(url, {
      method: 'POST',
      body: sendRawBody ? body : JSON.stringify(body),
      ...configRest,
      headers: { ...combineHeaders(config.headers) }
    });

    return await handleResponseAsync(response, config);
  },

  async postFormData<T = unknown>(
    url: string,
    body: FormData,
    config?: ApiRequestConfig
  ): Promise<T> {
    config = config || {};
    const response = await fetch(url, {
      method: 'POST',
      body,
      ...config,
      headers: { ...combineHeaders(config.headers, false) }
    });

    return await handleResponseAsync(response, config);
  },

  canSendBeacon(): boolean {
    return window && 'sendBeacon' in window.navigator;
  },

  sendBeacon(url: string, body?: any): boolean {
    if (!(window && 'sendBeacon' in window.navigator)) {
      return false;
    }
    return navigator.sendBeacon(
      url,
      body
        ? new Blob([JSON.stringify(body)], { type: 'application/json' })
        : undefined
    );
  },

  async putAsync<T = unknown>(
    url: string,
    body?: any,
    config?: ApiRequestConfig
  ): Promise<T> {
    config = config || {};
    const res = await fetch(url, {
      method: 'PUT',
      body: !body ? undefined : JSON.stringify(body),
      ...config,
      headers: { ...combineHeaders(config.headers) }
    });

    if (!isOk(res)) {
      await handleErrorAsync(res, config);
      return null;
    }

    if (config.responseHandler) {
      const result = config.responseHandler(res);
      if (result !== undefined) {
        return result;
      }
    }

    return isJson(res.headers) ? await res.json() : await res.text();
  },

  async deleteAsync<T = unknown>(
    url: string,
    config?: ApiRequestConfig
  ): Promise<T> {
    config = config || {};
    const res = await fetch(url, {
      method: 'DELETE',
      ...config,
      headers: { ...combineHeaders(config.headers) }
    });

    if (!isOk(res)) {
      await handleErrorAsync(res, config);
      return null;
    }

    if (config.responseHandler) {
      const result = config.responseHandler(res);
      if (result !== undefined) {
        return result;
      }
    }

    return isJson(res.headers) ? await res.json() : await res.text();
  }
};
