import isArray from 'lodash/isArray';

export class QueryParams {
  private parts: any = {};

  constructor(data?: Record<string, any>) {
    if (!data) {
      return;
    }
    for (const key in data) {
      if (!Object.prototype.hasOwnProperty.call(data, key)) {
        continue;
      }
      this.set(key, data[key]);
    }
  }

  getParts() {
    return this.parts;
  }
  /**
   * Returns the number of keys in the query string.
   */
  length(): number {
    return Object.keys(this.parts).length;
  }

  /**
   * Appends a specified key/value pair as a new search parameter.
   */
  append(name: string, value: string): QueryParams {
    QueryParams.appendTo(this.parts, name, value);
    return this;
  }

  /**
   * Deletes the given search parameter, and its associated value,
   * from the list of all search parameters.
   */
  delete(name: string): QueryParams {
    if (this.parts[name]) {
      delete this.parts[name];
    }
    return this;
  }

  /**
   * Deletes many values associated by their individual search parameter.
   */
  deleteMany(data: string[]): QueryParams {
    for (const key of data) {
      if (this.parts[key]) {
        delete this.parts[key];
      }
    }

    return this;
  }

  /**
   * Returns the first value associated to the given search parameter.
   */
  get(name: string): string | undefined {
    return name in this.parts ? this.parts[name][0] : undefined;
  }

  /**
   * Returns all the values association with a given search parameter.
   */
  getAll(name: string): string[] {
    return name in this.parts ? this.parts[name].slice(0) : [];
  }

  /**
   * Returns a Boolean indicating if such a search parameter exists.
   */
  has(name: string, ignoreCase: boolean = false): boolean {
    return Object.keys(this.parts).some((x) => {
      if (ignoreCase) return x.toLowerCase() === (name || '').toLowerCase();
      return x === name;
    });
  }

  /**
   * Sets the value associated to a given search parameter to
   * the given value. If there were several values, delete the
   * others.
   */
  set(name: string, value: any | any[]): QueryParams {
    if (typeof value === 'undefined' || value === null) {
      return this;
    }
    if (isArray(value)) {
      if (value.length === 0) {
        return this;
      }
      this.parts[name] = value.map((x) => QueryParams.getValue(x));
    } else {
      this.parts[name] = [QueryParams.getValue(value)];
    }
    return this;
  }

  /**
   * Sets many values associated by their individual search parameter and value.
   */
  setMany(data?: Record<string, any>): QueryParams {
    if (data) {
      Object.entries(data).forEach(([key, value]) => this.set(key, value));
    }

    return this;
  }

  /**
   * Sets the common "skip" and "take" params used in paginated searches.
   * @param skip The number of records to skip
   * @param take
   */
  setTakeSkip(take: number, skip?: number): QueryParams {
    this.set('take', Math.max(1, isNaN(take) ? 1 : take).toString());
    if (skip && !isNaN(skip)) {
      this.set('skip', Math.max(0, skip || 0).toString());
    }
    return this;
  }

  /**
   * Returns a string containing a query string suitable for use in a URL.
   * @param includeStart Indicates if the "?" character should be included at the start of the query.
   */
  toString(includeStart: boolean = true): string {
    const query: string[] = [];
    let i: number;
    let key: string;
    let name: string;
    let value: string;
    for (key in this.parts) {
      if (!Object.prototype.hasOwnProperty.call(this.parts, key)) {
        continue;
      }
      name = QueryParams.encode(key);
      i = 0;
      for (value = this.parts[key]; i < value.length; i++) {
        query.push(`${name}=${QueryParams.encode(value[i])}`);
      }
    }
    if (query.length === 0) {
      return '';
    }
    return (includeStart ? '?' : '') + query.join('&');
  }

  public static build(data?: Record<string, any>) {
    return new QueryParams(data);
  }

  private static getValue(value: any) {
    if (value instanceof Date) {
      return value.toISOString();
    }
    return typeof value === 'undefined' && value === null
      ? value
      : value.toString();
  }

  private static appendTo(dict: any, name: string, value: string): void {
    if (name in dict) {
      dict[name].push(QueryParams.getValue(value));
    } else {
      dict[name] = [QueryParams.getValue(value)];
    }
  }

  static encode(str: string): string {
    const replace: any = {
      '!': '%21',
      "'": '%27',
      '(': '%28',
      ')': '%29',
      '~': '%7E',
      '%20': '+',
      '%00': '\x00'
    };

    // "Plus sign is reserved as shorthand notation for a space" - https://www.w3.org/Addressing/URL/uri-spec.html
    // When @param str contains plus sign spaces, such as when read directly from the url, sanitize to prevent encoding
    // char literal '+'. If char literal '+' is required, should consider pre-encoding.
    const sanitizedStr = str.replace(/\+/g, ' ');

    return encodeURIComponent(sanitizedStr).replace(
      /[!'()~]|%20|%00/g,
      (match) => {
        return replace[match];
      }
    );
  }
}
