import isValid from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';
import isDate from 'lodash/isDate';
import isNil from 'lodash/isNil';

const hasTimezone = (value: string) =>
  value.endsWith('Z') || value.indexOf('+') > -1;

/**
 * Some dates are formatted as "2020-06-23 12:32:02", we need to add
 * "Z" to the end to force to UTC rather than being parsed in the
 * local time zone
 * @param value The value of the date.
 */
const getUtcDate = (value: string) =>
  hasTimezone(value) ? value : value + 'Z';

export const utcDate = (
  value: Date | string | number | undefined,
  defaultValue: () => Date
): Date => {
  if (isNil(value)) {
    return defaultValue();
  }
  if (isDate(value)) {
    return value as Date;
  }
  try {
    switch (typeof value) {
      case 'number':
        return new Date(value);
      case 'string': {
        // parseISO does not throw on invalid date, it returns the string "Invalid Date" instead
        const date = parseISO(getUtcDate(value));
        if (isValid(date)) {
          return date;
        } else {
          return defaultValue();
        }
      }
      case 'object': {
        const date = parseISO(getUtcDate((value as any).toString()));
        if (isValid(date)) {
          return date;
        } else {
          return defaultValue();
        }
      }
    }
  } catch {}
  return defaultValue();
};

export const utcDateOrNow = (value: Date | string | number | undefined) =>
  utcDate(value, () => new Date());

export const utcDateOrUndefined = (value: Date | string | number | undefined) =>
  utcDate(value, () => undefined);

export const utcDateOrNull = (value: Date | string | number | undefined) =>
  utcDate(value, () => null);
