export const ApiHeaders = {
  Authorization: 'Authorization',
  CompanyId: 'X-Company-ID',
  EmbeddedHostUrl: 'X-Embedded-Host-URL',
  PageId: 'X-Komo-Page-Id',
  PageSlug: 'X-Komo-Page-Slug',
  UserSessionId: 'X-Komo-User-Session-Id',
  Ssr: 'X-Komo-Ssr',
  SsrFingerprintId: 'X-Komo-Ssr-Fingerprint-Id',
  SsrProfileId: 'X-Komo-Ssr-Profile-Id'
};

export const ApiQueryDefaults = {
  retries: 5
};
