import isNil from 'lodash/isNil';

//https://github.com/ekg/fraction.js
export class Fraction {
  numerator: number;
  denominator: number;

  constructor(
    numeratorOrRatio: string | number | Fraction,
    denominator?: string | number
  ) {
    /* double argument invocation */
    if (typeof numeratorOrRatio !== 'undefined' && denominator) {
      if (
        (typeof numeratorOrRatio === 'number' ||
          numeratorOrRatio instanceof Number) &&
        (typeof denominator === 'number' ||
          (denominator as any) instanceof Number)
      ) {
        this.numerator = numeratorOrRatio as number;
        this.denominator = denominator as number;
      } else if (
        (typeof numeratorOrRatio === 'string' ||
          numeratorOrRatio instanceof String) &&
        (typeof denominator === 'string' ||
          (denominator as any) instanceof String)
      ) {
        // what are they?
        // hmm....
        // assume they are floats?
        this.numerator = parseFloat(numeratorOrRatio.replace(',', '.'));
        this.denominator = parseFloat(
          (denominator as string).replace(',', '.')
        );
      }
      // TODO: should we handle cases when one argument is String and another is Number?
      /* single-argument invocation */
    } else if (typeof denominator === 'undefined') {
      const num = numeratorOrRatio; // swap variable names for legibility
      if (num instanceof Fraction) {
        this.numerator = num.numerator;
        this.denominator = num.denominator;
      } else if (typeof num === 'number' || (num as any) instanceof Number) {
        // just a straight number init
        this.numerator = num as number;
        this.denominator = 1;
      } else if (typeof num === 'string' || (num as any) instanceof String) {
        let a, b; // hold the first and second part of the fraction, e.g. a = '1' and b = '2/3' in 1 2/3
        // or a = '2/3' and b = undefined if we are just passed a single-part number
        const arr = num.split(' ');
        if (arr[0]) a = arr[0];
        if (arr[1]) b = arr[1];
        /* compound fraction e.g. 'A B/C' */
        //  if a is an integer ...
        if (a % 1 === 0 && b && b.match('/')) {
          const f = new Fraction(a);
          f.add(b);
          this.denominator = f.denominator;
          this.numerator = f.numerator;
        } else if (a && !b) {
          /* simple fraction e.g. 'A/B' */
          if ((typeof a === 'string' || a instanceof String) && a.match('/')) {
            // it's not a whole number... it's actually a fraction without a whole part written
            const f = a.split('/');
            this.numerator = parseFloat(f[0]);
            this.denominator = parseFloat(f[1]);
            /* string floating point */
          } else if (
            (typeof a === 'string' || a instanceof String) &&
            a.match('.')
          ) {
            const f = new Fraction(parseFloat(a.replace(',', '.')));
            this.numerator = f.numerator;
            this.denominator = f.denominator;
            /* whole number e.g. 'A' */
          } else {
            // just passed a whole number as a string
            this.numerator = parseInt(a);
            this.denominator = 1;
          }
        } else {
          throw 'could not parse';
        }
      }
    }

    if (isNil(this.denominator)) {
      throw 'denominator null';
    }

    if (isNil(this.numerator)) {
      throw 'numerator null';
    }
    this.sanitise();
  }

  clone() {
    return new Fraction(this.numerator, this.denominator);
  }

  toString() {
    if (isNaN(this.denominator)) return '-';
    let result = '';
    if (this.numerator < 0 != this.denominator < 0) result = '-';
    const numerator = Math.abs(this.numerator);
    const denominator = Math.abs(this.denominator);
    return `${result}${numerator}/${denominator}`;
  }

  toTeX(mixed?: boolean) {
    if (isNaN(this.denominator)) return 'NaN';
    let result = '';
    if (this.numerator < 0 != this.denominator < 0) result = '-';
    let numerator = Math.abs(this.numerator);
    const denominator = Math.abs(this.denominator);

    if (!mixed) {
      //We want a simple fraction, without wholepart extracted
      if (denominator === 1) return result + numerator;
      else return result + '\\frac{' + numerator + '}{' + denominator + '}';
    }
    const wholepart = Math.floor(numerator / denominator);
    numerator = numerator % denominator;
    if (wholepart != 0) result += wholepart;
    if (numerator != 0)
      result += '\\frac{' + numerator + '}{' + denominator + '}';
    return result.length > 0 ? result : '0';
  }

  rescale(factor: number) {
    this.numerator *= factor;
    this.denominator *= factor;
    return this;
  }

  add(value: Fraction | number) {
    if (value instanceof Fraction) {
      value = value.clone();
    } else {
      value = new Fraction(value);
    }
    const td = this.denominator;
    this.rescale(value.denominator);
    this.numerator += value.numerator * td;
    this.sanitise();
  }

  subtract(value: Fraction | number) {
    if (value instanceof Fraction) {
      value = value.clone(); // we scale our argument destructively, so clone
    } else {
      value = new Fraction(value);
    }
    const td = value.denominator;
    this.rescale(value.denominator);
    this.numerator -= value.numerator * td;

    this.sanitise();
  }

  multiply(value: Fraction | number) {
    if (value instanceof Fraction) {
      this.numerator *= value.numerator;
      this.denominator *= value.denominator;
    } else if (typeof value === 'number') {
      this.numerator *= value;
    } else {
      this.multiply(new Fraction(value));
    }

    this.sanitise();
  }

  divide(value: Fraction | number) {
    if (value instanceof Fraction) {
      this.numerator *= value.denominator;
      this.denominator *= value.numerator;
    } else if (typeof value === 'number') {
      this.denominator *= value;
    } else {
      this.divide(new Fraction(value));
      return;
    }

    this.sanitise();
  }

  equals(value: Fraction | number) {
    if (!(value instanceof Fraction)) {
      value = new Fraction(value);
    }
    // fractions that are equal should have equal normalized forms
    const a = this.clone();
    a.clone();
    const b = value.clone();
    b.clone();

    return (
      a.numerator === value.numerator && a.denominator === value.denominator
    );
  }

  sanitise() {
    const isFloat = (n: any) => {
      return (
        typeof n === 'number' &&
        ((n > 0 && n % 1 > 0 && n % 1 < 1) ||
          (n < 0 && n % -1 < 0 && n % -1 > -1))
      );
    };

    const roundToPlaces = (n: number, places: number) => {
      if (!places) {
        return Math.round(n);
      } else {
        const scalar = Math.pow(10, places);
        return Math.round(n * scalar) / scalar;
      }
    };

    // added for the case where decimal is ending with .999999 ex 8.999999999
    const makeString = (n: number) => {
      const ans = n.toString();
      // no decimal point is present, then we add a decimal point
      if (ans.indexOf('.') === -1) {
        return ans + '.0';
      }
      // else return as it is
      return ans;
    };

    // TODO hackish.  Is there a better way to address this issue?
    //
    /* first check if we have decimals, and if we do eliminate them
     * multiply by the 10 ^ number of decimal places in the number
     * round the number to nine decimal places
     * to avoid js floating point funnies
     */
    if (isFloat(this.denominator)) {
      const rounded = roundToPlaces(this.denominator, 9);
      const scaleup = Math.pow(10, makeString(rounded).split('.')[1].length);
      this.denominator = Math.round(this.denominator * scaleup); // this !!! should be a whole number
      //this.numerator *= scaleup;
      this.numerator *= scaleup;
    }
    if (isFloat(this.numerator)) {
      const rounded = roundToPlaces(this.numerator, 9);
      const scaleup = Math.pow(10, makeString(rounded).split('.')[1].length);
      this.numerator = Math.round(this.numerator * scaleup); // this !!! should be a whole number
      //this.numerator *= scaleup;
      this.denominator *= scaleup;
    }
    const gcf = Fraction.gcf(this.numerator, this.denominator);
    this.numerator /= gcf;
    this.denominator /= gcf;
    if (this.denominator < 0) {
      this.numerator *= -1;
      this.denominator *= -1;
    }
  }

  snap(max?: number, threshold?: number) {
    if (!threshold) threshold = 0.0001;
    if (!max) max = 100;

    const negative = this.numerator < 0;
    const decimal = this.numerator / this.denominator;
    const fraction = Math.abs(decimal % 1);
    const remainder = negative ? Math.ceil(decimal) : Math.floor(decimal);

    for (let denominator = 1; denominator <= max; ++denominator) {
      for (let numerator = 0; numerator <= max; ++numerator) {
        const approximation = Math.abs(numerator / denominator);
        if (Math.abs(approximation - fraction) < threshold) {
          return new Fraction(
            remainder * denominator + numerator * (negative ? -1 : 1),
            denominator
          );
        }
      }
    }

    return new Fraction(this.numerator, this.denominator);
  }

  static gcf(a: number, b: number) {
    if (arguments.length < 2) {
      return a;
    }
    let c;
    a = Math.abs(a);
    b = Math.abs(b);
    /*  //It seems to be no need in these checks
      // Same as isNaN() but faster
      if (a !== a || b !== b) {
          return NaN;
      }
      //Same as !isFinite() but faster
      if (a === Infinity || a === -Infinity || b === Infinity || b === -Infinity) {
          return Infinity;
       }
       // Checks if a or b are decimals
       if ((a % 1 !== 0) || (b % 1 !== 0)) {
           throw new Error("Can only operate on integers");
       }
  */

    while (b) {
      c = a % b;
      a = b;
      b = c;
    }
    return a;
  }
}

export class FractionBuilder {
  private readonly _fraction: Fraction;

  constructor(
    numeratorOrRatio: string | string | number | number | Fraction,
    denominator?: string | string | number | number
  ) {
    this._fraction = new Fraction(numeratorOrRatio, denominator);
  }

  build() {
    return this._fraction;
  }

  add(value: Fraction | number) {
    this._fraction.add(value);
    return this;
  }

  subtract(value: Fraction | number) {
    this._fraction.subtract(value);
    return this;
  }

  multiply(value: Fraction | number) {
    this._fraction.multiply(value);
    return this;
  }

  divide(value: Fraction | number) {
    this._fraction.divide(value);
    return this;
  }
}
