import { decode, encode } from 'blurhash';
import isNil from 'lodash/isNil';
import { LRUCache } from 'lru-cache';

import { isServer } from './browser';
import { getPngArray, pngEncode } from './pngEncode';
import { getClampedSize } from './size';

const loadImageAsync = (src: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => resolve(img);
    img.onerror = (...args) => reject(args);
    img.src = src;
  });

const fallbackHash = 'L6Pj0^i_.AyE_3t7t7R**0o#DgR4';

export const tryEncodeImageBlurHashFromFileAsync = async (
  file: File | Blob,
  resolutionX: number = 4,
  resolutionY: number = 3
): Promise<BlurHashResponse> => {
  try {
    const image = await loadImageAsync(URL.createObjectURL(file));
    return encodeImageBlurHash(image, resolutionX, resolutionY);
  } catch (e) {
    console.error(e, 'Failed to encode blur hash');
    return null;
  }
};

export const tryEncodeImageBlurHashFromUrlAsync = async (
  url: string,
  resolutionX: number = 4,
  resolutionY: number = 3
): Promise<BlurHashResponse> => {
  try {
    const image = await loadImageAsync(url);
    return encodeImageBlurHash(image, resolutionX, resolutionY);
  } catch (e) {
    console.error(e, `Failed to encode blur hash for ${url}`);
    return null;
  }
};

const encodeImageBlurHash = (
  image: HTMLImageElement,
  resolutionX: number = 4,
  resolutionY: number = 3
): BlurHashResponse => {
  const canvas = document.createElement('canvas');
  const clampedSize = getClampedSize({
    size: { height: image.naturalHeight, width: image.naturalWidth },
    max: 64
  });
  canvas.width = clampedSize.width;
  canvas.height = clampedSize.height;
  const context = canvas.getContext('2d');
  context.drawImage(image, 0, 0, canvas.width, canvas.height);
  const data = context.getImageData(0, 0, canvas.width, canvas.height);
  return {
    naturalWidth: image.naturalWidth,
    naturalHeight: image.naturalHeight,
    blurHash: encode(
      data.data,
      data.width,
      data.height,
      resolutionX,
      resolutionY
    ),
    htmlImage: image
  };
};

export interface BlurHashResponse {
  naturalWidth: number;
  naturalHeight: number;
  blurHash: string;
  htmlImage: HTMLImageElement;
}

export const decodeBlurHash = (options: {
  hash: string;
  width: number;
  height: number;
  retryWithFallback?: boolean;
}) => {
  const {
    hash,
    width: widthProp,
    height: heightProp,
    retryWithFallback = true
  } = options;
  const height = isNil(heightProp) || isNaN(heightProp) ? 100 : heightProp;
  const width = isNil(widthProp) || isNaN(widthProp) ? 100 : widthProp;

  try {
    const clampedSize = getClampedSize({
      size: { width, height },
      max: 16,
      roundingDecimals: 0
    });

    //Need to ensure that the height and width are not 0
    //Also needs to be an integer as canvas 2d context does not support floating point values
    if (clampedSize.height <= 0) {
      clampedSize.height = 1;
    }

    if (clampedSize.width <= 0) {
      clampedSize.width = 1;
    }

    return blurHashToDataURL({
      hash,
      width: clampedSize.width,
      height: clampedSize.height
    });
  } catch (e) {
    console.error(e, `Failed to decode blurhash ${hash}`);
    if (retryWithFallback) {
      return decodeBlurHash({
        hash: fallbackHash,
        width,
        height,
        retryWithFallback: false
      });
    } else {
      throw e;
    }
  }
};

const cacheOptions = {
  max: 500,

  // how long to live in ms
  ttl: 1000 * 60 * 10 // 10 mins
};
const cache = new LRUCache<string, string>(cacheOptions);

const blurHashToDataURL = (options: {
  hash: string;
  height: number;
  width: number;
}) => {
  const { hash, height: heightProp, width: widthProp } = options;
  if (!hash) return undefined;

  const cachedBlurDataURL = cache.get(hash);

  if (cachedBlurDataURL) {
    return cachedBlurDataURL;
  }

  const height = isNil(heightProp) || isNaN(heightProp) ? 32 : heightProp;
  const width = isNil(widthProp) || isNaN(widthProp) ? 32 : widthProp;

  const pixelData = decode(hash, width, height);

  const dataUrl = decodeToDataUrl(pixelData, width, height);

  cache.set(hash, dataUrl);
  return dataUrl;
};

const decodeToDataUrl = (
  pixelData: Uint8ClampedArray,
  width: number,
  height: number
) => {
  // decode without canvas - this is a mashup of a couple of forks of this gist: https://gist.github.com/mattiaz9/53cb67040fa135cb395b1d015a200aff
  if (isServer()) {
    const pngString = pngEncode(width, height, pixelData);
    const base64 = Buffer.from(getPngArray(pngString)).toString('base64');

    return `data:image/png;base64,${base64}`;
  }

  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const context = canvas.getContext('2d');
  const imageData = context.createImageData(width, height);
  imageData.data.set(pixelData);
  context.putImageData(imageData, 0, 0);
  return canvas.toDataURL('image/jpeg', 0.82);
};
